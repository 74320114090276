<template>
  <v-container fluid>
    <v-card-title>
      콘텐츠 캐러셀
      <v-spacer />
      <v-btn outlined to="/carousel/create">
        <v-icon left>mdi-plus</v-icon>
        등록
      </v-btn>
    </v-card-title>

    <v-card-subtitle class="py-0 pb-3">
      연구소탭에 묶어서 노출하고 싶은 콘텐츠의 투명라벨을 등록해주세요.
    </v-card-subtitle>

    <v-card flat outlined>
      <v-data-table v-model="select" show-select :headers="headers" :items="carouselList" @click:row="(item) => $router.push(`${$route.path}/${item.id}`)">
        <template v-if="changeIndex" v-slot:body>
          <draggable v-model="carouselList" tag="tbody">
            <tr v-for="(item, index) in carouselList" :key="index">
              <td><v-icon>mdi-menu</v-icon></td>
              <td>{{ item.index }}</td>
              <td>{{ item.title }}</td>
              <td>{{ new Date(item.publishedAt) > new Date() ? '노출' : item.publishedAt ? '노출' : '비노출' }}</td>
              <td><QuestionInvisibleTag small :tags="[item.InvisibleStamp.name]" /></td>
              <td>{{ item.showType }}</td>
              <td>{{ day(item.updatedAt) }}</td>
            </tr>
          </draggable>
        </template>

        <template v-slot:item.title="{ item }">
          <span>{{ item.title }}</span>
        </template>

        <template v-slot:item.publishedAt="{ item }">
          {{ new Date(item.publishedAt) > new Date() ? '노출' : item.publishedAt ? '노출' : '비노출' }}
        </template>

        <template v-slot:item.InvisibleStamp="{ item }">
          <QuestionInvisibleTag small :tags="[item.InvisibleStamp.name]" />
        </template>

        <template v-slot:item.showType="{ item }">
          <span>{{ item.showType }}</span>
        </template>

        <template v-slot:item.updatedAt="{ item }">
          {{ day(item.updatedAt) }}
        </template>

        <template v-slot:footer>
          <v-divider />
          <v-container fluid class="row" no-gutters>
            <v-btn icon class="mr-3" @click="deleteCarousel">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>

            <v-spacer />

            <v-btn v-if="changeIndex" class="mr-3" color="red" dark @click="cancelOrder">
              취소
            </v-btn>
            <v-btn :color="changeIndex ? 'green' : null" dark @click="changeIndex ? setOrder() : (changeIndex = !changeIndex)">
              {{ changeIndex ? '완료' : '순서 변경하기' }}
            </v-btn>
          </v-container>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { defineComponent, onBeforeMount, reactive, toRefs } from '@vue/composition-api'
import { day } from '@/helper'
import draggable from 'vuedraggable'
import QuestionInvisibleTag from '@/components/QuestionInvisibleTag.vue'

export default defineComponent({
  components: {
    draggable,
    QuestionInvisibleTag,
  },

  setup(props, { root }) {
    const state = reactive({
      select: [],
      changeIndex: false,
      headers: [
        { text: '순서', value: 'index' },
        { text: '타이틀 문구', value: 'title' },
        { text: '노출여부', value: 'publishedAt' },
        { text: '투명라벨', value: 'InvisibleStamp' },
        { text: '노출 콘텐츠', value: 'showType' },
        { text: '최종 등록일시', value: 'updatedAt' },
      ],
      showType: 'all',
      carouselList: [],
      stampName: '',
      index: null,
    })

    const getListCarousel = async () => {
      try {
        state.carouselList = await root.$store.dispatch('carousel/getListCarousel', {
          showType: state.showType,
        })

        state.carouselList.sort((a, b) => a.index - b.index)
      } catch (err) {
        console.log(err)
        alert(err)
      }
    }

    const setOrder = async () => {
      let count = 1
      for (const i in state.carouselList) {
        await root.$store.dispatch('carousel/updateCarousel', {
          id: Number(state.carouselList[i].id),
          data: {
            index: count++,
          },
        })
      }
      await getListCarousel()
      state.changeIndex = false
    }

    const cancelOrder = async () => {
      await getListCarousel()
      state.changeIndex = false
    }

    const deleteCarousel = async () => {
      try {
        if (!confirm('캐러셀을 삭제합니다.')) return

        for (const e of state.select) {
          await root.$store.dispatch('carousel/deleteCarousel', {
            id: Number(e.id),
          })
        }

        state.select = []

        getListCarousel()
      } catch (err) {
        console.error(err)
        alert(err)
      }
    }

    onBeforeMount(async () => {
      await getListCarousel()
      setOrder()
    })

    return {
      ...toRefs(state),
      getListCarousel,
      setOrder,
      cancelOrder,
      deleteCarousel,
      day,
    }
  },
})
</script>
