var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card-title',[_vm._v(" 콘텐츠 캐러셀 "),_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","to":"/carousel/create"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" 등록 ")],1)],1),_c('v-card-subtitle',{staticClass:"py-0 pb-3"},[_vm._v(" 연구소탭에 묶어서 노출하고 싶은 콘텐츠의 투명라벨을 등록해주세요. ")]),_c('v-card',{attrs:{"flat":"","outlined":""}},[_c('v-data-table',{attrs:{"show-select":"","headers":_vm.headers,"items":_vm.carouselList},on:{"click:row":function (item) { return _vm.$router.push(((_vm.$route.path) + "/" + (item.id))); }},scopedSlots:_vm._u([(_vm.changeIndex)?{key:"body",fn:function(){return [_c('draggable',{attrs:{"tag":"tbody"},model:{value:(_vm.carouselList),callback:function ($$v) {_vm.carouselList=$$v},expression:"carouselList"}},_vm._l((_vm.carouselList),function(item,index){return _c('tr',{key:index},[_c('td',[_c('v-icon',[_vm._v("mdi-menu")])],1),_c('td',[_vm._v(_vm._s(item.index))]),_c('td',[_vm._v(_vm._s(item.title))]),_c('td',[_vm._v(_vm._s(new Date(item.publishedAt) > new Date() ? '노출' : item.publishedAt ? '노출' : '비노출'))]),_c('td',[_c('QuestionInvisibleTag',{attrs:{"small":"","tags":[item.InvisibleStamp.name]}})],1),_c('td',[_vm._v(_vm._s(item.showType))]),_c('td',[_vm._v(_vm._s(_vm.day(item.updatedAt)))])])}),0)]},proxy:true}:null,{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.title))])]}},{key:"item.publishedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.publishedAt) > new Date() ? '노출' : item.publishedAt ? '노출' : '비노출')+" ")]}},{key:"item.InvisibleStamp",fn:function(ref){
var item = ref.item;
return [_c('QuestionInvisibleTag',{attrs:{"small":"","tags":[item.InvisibleStamp.name]}})]}},{key:"item.showType",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.showType))])]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.day(item.updatedAt))+" ")]}},{key:"footer",fn:function(){return [_c('v-divider'),_c('v-container',{staticClass:"row",attrs:{"fluid":"","no-gutters":""}},[_c('v-btn',{staticClass:"mr-3",attrs:{"icon":""},on:{"click":_vm.deleteCarousel}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1),_c('v-spacer'),(_vm.changeIndex)?_c('v-btn',{staticClass:"mr-3",attrs:{"color":"red","dark":""},on:{"click":_vm.cancelOrder}},[_vm._v(" 취소 ")]):_vm._e(),_c('v-btn',{attrs:{"color":_vm.changeIndex ? 'green' : null,"dark":""},on:{"click":function($event){_vm.changeIndex ? _vm.setOrder() : (_vm.changeIndex = !_vm.changeIndex)}}},[_vm._v(" "+_vm._s(_vm.changeIndex ? '완료' : '순서 변경하기')+" ")])],1)]},proxy:true}],null,true),model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }